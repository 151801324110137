body {
    background-color: rgba(18, 13, 10, 0.9);
    font-family: 'Archivo Narrow', sans-serif;
}

#header {
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
}

#header h1 {
    font-family: 'Permanent Marker', cursive;
    padding-top: 10px;
    padding-bottom: 14px;
    font-size: 1.75rem;
}

#search {
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
}

#search:focus {
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(255, 100, 255, 0.5); */
    box-shadow: none;
    border-color: rgba(0, 255, 0, 0.6);
}

.table {
    background-color: rgb(255, 255, 255);
}

.footer {
    color: rgb(255, 255, 255);
    text-align: center;
    padding-bottom: 10px;
    font-size: 0.8rem;
}

@media (min-width: 576px) {
    #header h1 {
        font-size: 2.5rem;
    }
}

@media (min-width: 1200px) {
    #header .img-fluid {
        max-width: 1110px;
    }
 }
